<template>
    <div class="common_content detail_box">
        <div class="back" @click="$router.go(-1)"><i class="el-icon-d-arrow-left" title="返回"/></div>
        <div class="news_detail_title">
            {{detail.title}}
        </div>
        <div class="publish_date">
            发布时间：{{detail.created_at}}
        </div>
         <el-divider></el-divider>
        <div class="news_detail_content" v-html="detail.extra.article"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            detail: {},
        }
    },
    computed: {
  
    },
    filters:{
  
    },
    created() {
        if (this.$route.query.id) {
            this.getCompanyNewsDetail(this.$route.query.id)
        }
        
    },
    methods:{
         // 获取新闻
        getCompanyNewsDetail(id) {
            let cond= {
                params:{
                    id
                }
            };
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.get(this.$apiUrl.common.getNewsDetail,cond).then((res) => {
                this.detail = res.content
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
    }
}
</script>
<style scoped>
.back{
    position: fixed;
    right: 23px;
    bottom: 62px;
    color: #ee660e;
    cursor: pointer;
    font-size: 24px;
}
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.detail_box{
    /* margin-right: 108px; */
    width: 960px;
    margin: 0 auto;
}
.news_detail_title{
    font-size: 16px;
    padding: 24px 0 8px 0px;
    text-align: center;
}
.publish_date{
    font-size: 12px;
        position: relative;
    top: 15px;
    text-align: center;
}
.news_detail_content{
    font-size: 14px;
}
</style>